import React, { useCallback, useState, useEffect } from "react";
import { Box, Button, TextField, Container } from "@mui/material";
import { useParams } from "react-router-dom";

import OpenBankingPlatformAPIInterface from "../openbankingplatform/OpenBankingPlatformAPIInterface";
import * as EmailValidator from "email-validator";
import PrimaryBlock from "../components/PrimaryBlock";
import Header from "../components/Header";
import LoadingSpinner from "../components/LoadingSpinner";
import { isJWT } from "../helpers/JwtHelper";

interface DataDeliveryProps {
    obApi: OpenBankingPlatformAPIInterface;
    basePath: string;
}


interface DataDeliveryState {
    password: string,
    email: string,
    code: string,
    dataDeliveryErrorMsg: string,
    downloadedMessage: string,
    isLoading: boolean,
    validateTokenMsg:string,
    attachments: Array<any>
}

const DataDelivery = (props: DataDeliveryProps) => {  
    const [state, setState] = useState<DataDeliveryState>({
        password: '',
        email: '',
        code: '',
        dataDeliveryErrorMsg: '',
        downloadedMessage: '',
        isLoading: true,
        validateTokenMsg:'',
        attachments: []
    });

    const {token} = useParams<{ token: any }>();
    const validateToken = useCallback(async () => {
        try {
            if(isJWT(token)) {
                const res = await props.obApi.validateDDToken();
                const code = res.trusted_adviser_broker_code;
                const attachments = res.attachments;
                console.log(code);
                console.log(attachments);
                setState(state => ({
                    ...state,
                    isLoading: false,
                    code: code,
                    attachments: attachments
                }))
            } else {
                // error page invalid token
                setState(state => ({
                    ...state,
                    isLoading: false,
                    validateTokenMsg: 'Invalid referral link. Please check the URL and try again.'
                }));
            }

        } catch (error) {
            //redirect to error page
            setState(state => ({
                ...state,
                isLoading: false,
                validateTokenMsg: (error as any).response.data.error_message || 'Token validation failed',
            }));
            // console.error('Token validation failed:', error);
        }
    }, [token, props.obApi]);

    
    // only works on first render
    useEffect(() => {
        (async() => {
            setState(state => ({
                ...state,
                isLoading: true,
            }))
            // await getCsrfToken();
            await validateToken();
        })();

        const redirectTimer = setTimeout(() => {
            setState(state => ({
                ...state,
                isLoading: false,
                dataDeliveryErrorMsg: "Your session has expired. Please refresh the page to continue."
            }));
        }, 5 * 60 * 1000); // 5 mins to expires
    
        // Cleanup function to clear the timer if the component unmounts
        return () => clearTimeout(redirectTimer);
    }, [validateToken]);





    const validateEmail = (newEmail?: string) => {
        if (newEmail) {
            return EmailValidator.validate(newEmail);
        }
        return false;
    }
    const hasValidEmail = validateEmail(state.email);
    const allMandatoryFieldsArePopulated = state.email !== '' && state.password !== '';

    const handleDataDelivery = () => {
        (async () => {
            try {
                setState({
                    ...state,
                    isLoading: true,
                });
                await props.obApi.getCsrfToken();
                const response = await props.obApi.downloadTADataDeliveryFile(state.email, state.password, state.code, state.attachments);
                setState({
                    ...state,
                    isLoading: false,
                    downloadedMessage: 'Downloading started... Please check your downloads folder for the file.'
                });

                //download
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'data.zip');
                document.body.appendChild(link);
                link.click();
                link.remove();
            } catch (error) {
                const responseBlob = (error as any).response.data;
                const text = await responseBlob.text();
                if (text) {
                    const resJson = JSON.parse(text);
                    console.error('Error downloading zip file:', resJson.error_message);
                    setState({
                        ...state,
                        isLoading: false,
                        dataDeliveryErrorMsg: `Oops! Something went wrong with the download: ${resJson.error_message}.`
                    })
                } else {
                    setState({
                        ...state,
                        isLoading: false,
                        dataDeliveryErrorMsg: `We encountered an unknown error while downloading the zip file. Please try again later or contact support if the issue persists.`
                    })
                }

            }
        })();
    }

    return (
        <>
            <div className={"page-top"}>
                <Header />
                <main>
                    <PrimaryBlock>
                        {state.isLoading ? (
                            <LoadingSpinner position={"fixed"} overlay />
                        ) : (
                            <>
                                <Container maxWidth="sm" className="success-confirmation">
                                    {state.dataDeliveryErrorMsg ? (
                                        <div>
                                            <p>{state.dataDeliveryErrorMsg}</p>
                                        </div>
                                    ) : state.validateTokenMsg ? (
                                        <div>
                                            <p>{state.validateTokenMsg}</p>
                                        </div>
                                    ) :state.downloadedMessage ? (
                                        <div>
                                            <p>{state.downloadedMessage}</p>
                                        </div>
                                    ) : (
                                        <>
                                            <div>
                                                <p>Please enter your email address and password to get the file access</p>
                                            </div>

                                            <div>
                                                <Box mt={4} component="form">
                                                    <TextField
                                                        style={{ paddingBottom: '20px' }}
                                                        label="Email"
                                                        type="email"
                                                        fullWidth
                                                        required
                                                        variant="outlined"
                                                        name="email-input"
                                                        id="email-input"
                                                        placeholder="Your Email Address"
                                                        value={state.email}
                                                        onChange={(event) => {
                                                            setState({
                                                                ...state,
                                                                email: event.target.value
                                                            })
                                                        }}
                                                    />
                                                    <br />
                                                    <TextField
                                                        style={{ paddingBottom: '20px' }}
                                                        label="Password"
                                                        type="password"
                                                        fullWidth
                                                        required
                                                        variant="outlined"
                                                        name="password-input"
                                                        id="password-input"
                                                        placeholder="Your Password"
                                                        value={state.password}
                                                        onChange={(event) => {
                                                            setState({
                                                                ...state,
                                                                password: event.target.value
                                                            })
                                                        }}
                                                    />
                                                    <div className="text-align-center">
                                                        <Button variant={"contained"} color={"secondary"} onClick={handleDataDelivery} disabled={!allMandatoryFieldsArePopulated || !hasValidEmail}>Send</Button>
                                                    </div>
                                                </Box>
                                            </div>
                                        </>
                                    )}

                                </Container>
                            </>)}
                    </PrimaryBlock>
                </main>
            </div >
        </>

    );
};

export default DataDelivery;